<template>
  <div class="booked-awards-history-container mt-2">
    <template v-if="historyList && historyList.length > 0">
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          lg="3"
          xl="3"
          v-for="item in historyList"
          :key="item.product.productId"
          class="product-col"
        >
          <AwardsCard
            :product="item.product"
            :showBtn="false"
            :buyDate="item.timeslotDate"
            :shippingAddress="item.shippingAddress"
            @bookProduct="bookProduct"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else-if="!loading">
      <span>{{ $t("awards.history.noAwards") }}</span>
    </template>
  </div>
</template>
<style lang="scss">
.booked-awards-history-container {
}
</style>
<script>
import AwardsCard from "@/components/awards/AwardsCard.vue";
import baskoCustomService from "@/service/baskoCustomService";
export default {
  name: "BookedAwardsHistoryList",
  props: {
    historyMode: { type: String, required: true }
  },
  components: { AwardsCard },
  data() {
    return {
      historyList: [],
      loading: true
    };
  },
  computed: {},
  methods: {
    async fetchBookedAwards() {
      try {
        this.historyList = [];
        this.loading = true;
        let res = null;
        if (this.historyMode === "booked") {
          res = await baskoCustomService.getAwardsOrders();
          if (res) {
            this.historyList = res.data.wishlistItems;
          }
        } else {
          // historymode === delivered
          res = await baskoCustomService.getAwardsStory();
          if (res) {
            this.historyList = res.data.wishlistItems;
          }
        }
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    }
  },
  mounted() {
    this.fetchBookedAwards();
  },
  watch: {
    historyMode() {
      this.fetchBookedAwards();
    }
  }
};
</script>
