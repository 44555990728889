<template>
  <div class="booker-awards-container">
    <CategoryTitle :category="category" />

    <div class="mt-7">
      {{ $t("awards.booked.primaCard") }}
      <strong>{{ user.fidelityCard }}</strong>
    </div>
    <div class="d-flex justify-end my-3">
      <v-btn outlined color="primary" large depressed :to="{ name: 'Awards' }">
        {{ $t("awards.booked.goToAwardsBtn")
        }}<v-icon small class="ml-2">$next</v-icon>
      </v-btn>
    </div>
    <v-divider />

    <v-btn-toggle
      class="my-5"
      mandatory
      color="white"
      v-model="historyModeSelected"
    >
      <v-btn color="primary" depressed value="booked">{{
        $t("awards.booked.bookedAwardsBtn")
      }}</v-btn>
      <v-btn color="primary" depressed value="delivered">{{
        $t("awards.booked.deliveredAwardsBtn")
      }}</v-btn>
    </v-btn-toggle>

    <BookedAwardsHistoryList :historyMode="historyModeSelected" />
  </div>
</template>
<style lang="scss"></style>
<script>
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import BookedAwardsHistoryList from "@/components/awards/BookedAwardsHistoryList.vue";

import categoryMixins from "~/mixins/category";

import { mapGetters } from "vuex";

export default {
  name: "BookedAwards",
  components: {
    CategoryTitle,
    BookedAwardsHistoryList
  },
  mixins: [categoryMixins],
  data() {
    return {
      historyModeSelected: "booked"
    };
  },
  computed: {
    ...mapGetters({
      user: "cart/getUser"
    })
  },
  methods: {},
  mounted() {}
};
</script>
